<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
	export default{
		name:"YjEdit",
		data(){
			return {
				
			}
		},
		methods:{
			
		}
	}
</script>

<style lang="scss">
	
</style>
